import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    content: String,
  }

  connect() {
    console.log("connected open-modal")
  }

  open() {
    kModal.init({
      content: this.contentValue,
      launchingUrl: "",
      ujs: true,
      namespace: "open-in-modal",
      autoClose: true,
      appendTo: document.body,
    });
  }
}
